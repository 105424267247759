import Loading from '../components/Loading';
import React from 'react';
import { useParams } from 'react-router-dom';

import { useAuth0 } from "@auth0/auth0-react";
import { fetchAuto } from '../services/fetchAuto';
import { useQuery } from 'react-query';

export default function ReportDetails() {

  const { reportId } = useParams();

  const { getAccessTokenSilently } = useAuth0();
  const auth0 = useAuth0();

  const filtersQuery = useQuery(
    ["get_token"],
    () => fetchAuto(`/getReportsById?id=${reportId}`, auth0, { method: "GET", throwError: false, getAccessTokenSilently, toJson: true }),
    {
      refetchOnWindowFocus: false,
      refetchInterval: false,
    }
  );

  if (!filtersQuery.data) {
    return <Loading/>
  }

  return (
    <div className='report-details'>
      <h1>Détails du Rapport</h1>
      <p>Description : {filtersQuery.data[0].description}</p>
      <p>Fausse Collection : {filtersQuery.data[0].fakeCollection}</p>
      <p>Collection Original : {filtersQuery.data[0].realCollection}</p>
      <p>Statut : {filtersQuery.data[0].status}</p>
    </div>
  );
};
