import React from "react"
import NavBar from "../components/Navbar";

export default function Callback() {

    return(
        <>
            <NavBar />
        </>
    )
}