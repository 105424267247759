import '../style/Style.css';
import Loading from '../components/Loading';
import React, { useState } from 'react';

import { useAuth0 } from "@auth0/auth0-react";
import { fetchAuto } from '../services/fetchAuto';
import { useQuery } from 'react-query';

export default function CollectionPage() {

    const [selectedOriginal, setSelectedOriginal] = useState('');
    const [selectedFake, setSelectedFake] = useState('');
    const [description, setDescription] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [reportId ] = useState('');
    
    const { user, getAccessTokenSilently } = useAuth0();
    const auth0 = useAuth0();
  
    const filtersQuery = useQuery(
        ["get_token"],
        () => fetchAuto(`/search?query=${searchQuery}`, auth0, { method: "GET", throwError: false, getAccessTokenSilently, toJson: true }),
        {
            refetchOnWindowFocus: false,
            refetchInterval: false,
        }
    );

    if (!filtersQuery.data) {
        return <Loading/>
      }

    const handleSelectCollection = (collectionName, type) => {
        if (type === 'original') {
          setSelectedOriginal(collectionName);
        } else if (type === 'fake') {
          setSelectedFake(collectionName);
        }
      };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const formData = {
            description,
            fakeCollection: selectedFake,
            realCollection: selectedOriginal,
            clientId: user.sub,
        };

        const response = await fetchAuto(
            `/addReport?description=${description}&&fakeCollection=${selectedFake}&&realCollection=${selectedOriginal}&&clientId=${formData.clientId}`, 
            auth0, 
            { 
                method: "POST", 
                throwError: false, 
                getAccessTokenSilently, 
                toJson: true 
            }
        );
    };

    return (
        <>
            <div className='content'>
                <div className='content-child'>
                <input type="text" value={searchQuery} onChange={e => {
                            setSearchQuery(e.target.value);
                            filtersQuery.refetch();
                        } 
                                                
                        }placeholder="Rechercher des collections"/>
                </div>
                <table>
                  <thead>
                      <tr>
                          <th>Image</th>
                          <th>Nom</th>
                          <th>Description</th>
                          <th>Page OpenSea</th>
                          <th>Ajouter</th>
                      </tr>
                  </thead>
                  <tbody>
                    {filtersQuery?.data?.map((collection) => (
                        <tr key={collection?.node?.id}>
                            <td>
                                <img src={collection?.node?.imageUrl} alt={collection?.node?.name} />
                            </td>
                            <td>{collection?.node?.name}</td>
                            <td>{collection?.node?.defaultChain.identifier}</td>
                            <td>
                                <a href={`https://opensea.io/collection/${collection?.node?.slug}`}> {collection?.node?.name}</a>
                            </td>
                            <td>
                                <button onClick={() => handleSelectCollection(`${collection?.node?.name}`, 'original')} className='real-collection'> Marquer comme Original </button>
                                <button onClick={() => handleSelectCollection(`${collection?.node?.name}`, 'fake')} className='fake-collection'> Marquer comme Fake </button>
                            </td>
                        </tr>
                    ))}
                  </tbody>
                </table>
                <h1>Formulaire de signalement</h1>
                <form onSubmit={handleSubmit}>
                    <p>Collection Original: {selectedOriginal}</p>
                    <p>Collection Fake: {selectedFake}</p>
                    <input type="text" value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Description"/>
                    <button type="submit" onClick={handleSubmit}>Envoyer</button>
                </form>
                {reportId && <p>Voici votre requête {reportId}</p>}
            </div>
        </>
    );
}