import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

export default function Home() {
    const { isAuthenticated, user } = useAuth0();

    return (
        <div className="home-container">
            <h1 className="home-title">Accueil</h1>
            {isAuthenticated ? (
                <>
                    <h2>Bonjour, {user.name}!</h2>
                </>
            ) : (
                <>
                    <h2>Bienvenue</h2>
                </>
            )}
        </div>
    );
}