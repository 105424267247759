import { useAuth0 } from '@auth0/auth0-react';

export const AuthServiceComponent = () => {
  const { loginWithRedirect, logout, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const handleAuthentication = async () => {
    if (isAuthenticated) {
      try {
        console.log("Waiting for the token");

        const token = await getAccessTokenSilently();
        console.log("Token:", token);

        document.cookie = `access_token=${token}; SameSite=None; Secure`;
        localStorage.setItem('access_token', token);
      } catch (error) {
        console.error("Error fetching the token", error);
      }
    }
  };

  const login = async (credentials) => {
    try {
      await loginWithRedirect({
        appState: {
          returnTo: "/",
        },
      });
    } catch (error) {
      console.error("Error during login:", error);
      throw error;
    }
  };

  const signup = async (userData) => {
    try {
      await loginWithRedirect({
        appState: {
          returnTo: "/",
        },
        authorizationParams: {
          screen_hint: "signup",
        },
      });
    } catch (error) {
      console.error("Error during signup:", error);
      throw error;
    }
  };

  const logoutUser = () => {
    localStorage.removeItem('access_token');
    logout({
      returnTo: window.location.origin,
    });
  };

  return {
    login,
    signup,
    logoutUser,
    handleAuthentication,
  };
};
