import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Auth0ProviderWithNavigate } from './components/Auth0ProviderWithNavigate';
import Navbar from './components/Navbar';
import Home from './pages/home';
import CollectionPage from "./pages/CollectionPage";
import ReportPage from './pages/ReportPage';
import ReportClientPage from './pages/ReportClientPage';
import DetailReportPage from "./pages/DetailReportPage";
import Callback from "./pages/callback-page";
import { QueryClient, QueryClientProvider } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';

const App = () => {
  return (
    <Router>
      <Auth0ProviderWithNavigate>
        <QueryClientProvider client={new QueryClient()}>
          <Navbar />
          <AppRouter />
        </QueryClientProvider>
      </Auth0ProviderWithNavigate>
    </Router>
  );
};

function AppRouter() {
  const { isAuthenticated } = useAuth0();

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/callback" element={<Callback />} />
      {isAuthenticated && (
        <>
          <Route path="/collection" element={<CollectionPage />} />
          <Route path="/allReports" element={<ReportPage />} />
          <Route path="/reports" element={<ReportClientPage />} />
          <Route path="/detailReport/:reportId" element={<DetailReportPage />} />
        </>
      )}
    </Routes>
  );
}

export default App;