import React from 'react';
import { Link } from 'react-router-dom';

import { useAuth0 } from "@auth0/auth0-react";
import { fetchAuto } from '../services/fetchAuto';
import { useQuery } from 'react-query';

export default function ReportPage() {

    const { getAccessTokenSilently } = useAuth0();
    const auth0 = useAuth0();

    const filtersQuery = useQuery(
        ["get_token"],
        () => fetchAuto(`/getReports`, auth0, { method: "GET", throwError: false, getAccessTokenSilently, toJson: true }),
        {
            refetchOnWindowFocus: false,
            refetchInterval: false,
        }
    );

    return (
        <>
            <table>
                <thead>
                    <tr>
                        <th>Numéro du signalement</th>
                        <th>Fausse Collection</th>
                        <th>Collection Original</th>
                        <th>Statut</th>
                        <th>Détail</th>
                    </tr>
                </thead>
                <tbody>
                        {filtersQuery?.data?.map((report) => (
                            <tr key={report.id}>
                                <td>Signalement : {report.id}</td>
                                <td>{report.fakeCollection}</td>
                                <td>{report.realCollection}</td>
                                <td>{report.status}</td>
                                <td>
                                    <Link to={`/detailReport/${report.id}`}>Plus de détail</Link>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </>
    )
}