import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import { LoginButton } from './Buttons/LoginButton';
import { LogoutButton } from './Buttons/LogoutButton';
import { SignupButton } from './Buttons/SignupButton';
import { AuthServiceComponent } from "../services/AuthServiceComponent";

const Navbar = () => {
  const { isAuthenticated } = useAuth0();
  const authService = AuthServiceComponent();

  useEffect(() => {
    const fetchData = async () => {
      await authService.handleAuthentication();
    };

    fetchData();
  }, [authService]);

  return (
    <nav>
        <button><Link to="/">Accueil</Link></button>
        
        {isAuthenticated && (
          <>
          <button><Link to="/collection">Rechercher une collection </Link></button>
          <button><Link to="/reports">Vos rapports </Link></button>
          <button><Link to="/allReports">Voir tous les rapports </Link></button>
          </>
        )}
      <div>
        {isAuthenticated ? (
          <>
            <LogoutButton />
          </>
        ) : (
          <>
            <LoginButton />
            <SignupButton />
          </>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
